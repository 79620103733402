<template>
  <v-menu v-model="visibleMenu" :close-on-content-click="false" rounded>
    <template v-slot:activator="{ on, attrs }">
      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
      >
        <v-btn
          :disabled="selected.length === 0 || processing"
          color="primary"
          class="text-capitalize"
          v-on="on"
          v-bind="attrs"
        >
          Confirm
        </v-btn>
      </transition>
    </template>

    <v-card>
      <v-list width="400">
        <v-list-item>
          <v-list-item-content class="pb-0">
            <v-list-item-title
              >Do you really want to confirm selected alert?
            </v-list-item-title>

            <!--COMMENT-->
            <v-row class="mx-0 mt-3">
              <v-col cols="12" class="py-0">
                <label class="font-roboto-condensed-regular font-size-15"
                >Comment</label
                >
                <v-textarea
                    v-model="comment"
                    placeholder="Comment"
                    auto-grow
                    rows="3"
                    dense
                    solo
                />
              </v-col>
            </v-row>
            <!--END COMMENT-->

            <v-list-item-subtitle class="text-right mt-2">
              <v-btn
                rounded
                text
                color="tertiary"
                small
                class="text-capitalize"
                @click="visibleMenu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                rounded
                text
                color="primary"
                small
                class="text-capitalize"
                @click="confirm"
              >
                Proceed
              </v-btn>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    selected: {
      type: Array,
      default: function () {
        return [];
      },
    },
    processing: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data() {
    return {
      visibleMenu: false,
      comment: "",
    };
  },
  methods: {
    confirm() {
      this.$emit("proceed", {status: true, comment: this.comment});
      this.visibleMenu = false;
    },
  },
};
</script>
