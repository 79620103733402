<template>
  <v-dialog v-model="show" v-if="data" width="auto" scrollable>
    <v-card color="primary" v-if="loading">
      <v-card-text>
        Please stand by
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-container v-else class="diff-wrapper">
      <v-row class="diff-wrapper-row">
        <v-col cols="12">
          <h3>Auditor content for {{ auditor.name }} will be updated</h3>
        </v-col>
        <v-col cols6>
          <div class="text-content">
            <div v-if="data.result">
              <h5 class="mt-2">Config stored at firewall:</h5>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template v-slot:actions>
                      <v-icon class="expansion-panel-header__icon"
                        >$expand</v-icon
                      >
                    </template>
                    <span class="expansion-panel-header__header"
                      >Formated data</span
                    >
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <xml-viewer
                      ref="viewer"
                      :xml="formattedXml(data.result)"
                      :class="$vuetify.theme.dark ? 'dark' : 'light'"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template v-slot:actions>
                      <v-icon class="expansion-panel-header__icon"
                        >$expand</v-icon
                      >
                    </template>
                    <span class="expansion-panel-header__header">Raw data</span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>
                      <small>{{ formattedXml(data.result) }}</small>
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="text-content">
            <div v-if="auditor.content">
              <h5>Config stored at database:</h5>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template v-slot:actions>
                      <v-icon class="expansion-panel-header__icon"
                        >$expand</v-icon
                      >
                    </template>
                    <span class="expansion-panel-header__header"
                      >Formated data</span
                    >
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <xml-viewer
                      ref="viewer"
                      :xml="formattedXml(auditor.content)"
                      :class="$vuetify.theme.dark ? 'dark' : 'light'"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template v-slot:actions>
                      <v-icon class="expansion-panel-header__icon"
                        >$expand</v-icon
                      >
                    </template>
                    <span class="expansion-panel-header__header">Raw data</span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>
                      <small>{{ formattedXml(auditor.content) }}</small>
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
          <span
            v-if="data.expected && data.result && data.expected !== data.result"
            >This config will be updated!</span
          >
        </v-col>
        <v-col cols="12">
          <v-btn
            rounded
            color="primary"
            class="text-capitalize bottom-btn font-ubuntu-bold"
            @click="onClose"
          >
            Close
          </v-btn>
          <v-btn
            :disabled="
              data.updating ||
              !(data.expected && data.result && data.expected !== data.result)
            "
            rounded
            outlined
            color="primary"
            class="text-capitalize bottom-btn font-ubuntu-bold mr-2"
            @click="onSubmit(data)"
          >
            Update auditor rule
            <v-icon small v-if="data.updating" class="ml-1">
              fas fa-spinner fa-spin
            </v-icon>
          </v-btn>
          <v-btn
            rounded
            outlined
            class="bottom-btn mr-2 font-ubuntu-bold"
            @click="isMask = !isMask"
          >
            Mask
            <v-icon class="mask" v-if="isMask">mdi-eye</v-icon
            ><v-icon class="mask" v-else>mdi-eye-off</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import {
  getFormattedConditions,
  getFormattedXml,
  maskTags,
} from "@/utils/auditor";
import { getAuditor } from "@/api/auditor/integrity";
import { Errors } from "@/utils/error";
import XmlViewer from "@/components/plugins/xmlViewer/XmlViewer";

export default {
  components: {
    XmlViewer,
  },
  props: {
    show: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    data: {
      type: Object,
      default: function () {
        return undefined;
      },
    },
  },
  watch: {
    show: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.visible = newValue;
        if (newValue) {
          this.loading = true;
          this.loadById();
        }
      },
    },
    visible: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!newValue) {
          this.onClose();
        }
      },
    },
  },
  data() {
    return {
      visible: false,
      loading: true,

      maskedTags: [],
      mask: "",
      isMask: true,

      auditor: undefined,
    };
  },
  methods: {
    loadById() {
      this.mask = this.data.mask;
      this.isMask = this.data.isMask;
      this.maskedTags = this.data.maskedTags;

      this.loading = true;
      getAuditor(this.data.auditor_id)
        .then((response) => {
          this.auditor = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    formattedXml(xml) {
      if (xml && this.maskedTags && this.mask && this.isMask) {
        return getFormattedXml(maskTags(xml, this.maskedTags, this.mask));
      }
      return getFormattedXml(xml);
    },
    formattedConditions(text) {
      return getFormattedConditions(text);
    },
    onClose() {
      this.$emit("onClose");
    },
    onSubmit() {
      this.$emit("onSubmit");
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--dark {
  .diff-wrapper {
    background-color: #1e1e1e;
    box-shadow: 13px 13px 30px rgb(0 0 0 / 30%);
    .diff-wrapper-row {
      background-color: #1e1e1e;
      box-shadow: 13px 13px 30px rgb(0 0 0 / 30%);
    }
  }
  .text-content {
    border: 0.5px solid #ffffff;
  }
}
.theme--light {
  .diff-wrapper {
    background-color: #ffffff;
    box-shadow: 13px 13px 30px rgba(0, 0, 0, 0.1);
    .diff-wrapper-row {
      background-color: #ffffff;
      box-shadow: 13px 13px 30px rgba(0, 0, 0, 0.1);
    }
  }
  .text-content {
    border: 0.5px solid #1e1e1e;
  }
}
.diff-wrapper {
  .diff-area {
    padding-bottom: 0.1em;
    color: #008;
    background-color: #ffffff;
    box-shadow: 13px 13px 30px rgba(0, 0, 0, 0.1);
    margin: 0;
  }
  .text-content {
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 0.5rem;
  }
  .bottom-btn {
    float: right;
  }
}
::v-deep .d2h-cntx {
  background-color: #fff;
}
::v-deep .d2h-emptyplaceholder {
  background-color: #f1f1f1;
}
::v-deep .d2h-code-line-prefix,
::v-deep .d2h-wrapper .hljs,
::v-deep .d2h-wrapper .hljs-section,
::v-deep .d2h-wrapper .hljs-keyword,
::v-deep .d2h-wrapper .hljs-string,
::v-deep .d2h-wrapper .hljs-literal,
::v-deep .d2h-wrapper .hljs-title,
::v-deep .d2h-wrapper .hljs-number,
::v-deep .d2h-wrapper .d2h-code-side-linenumber,
::v-deep .d2h-wrapper .d2h-code-side-line {
  color: #000;
}
::v-deep .d2h-wrapper tr {
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
}
::v-deep .d2h-wrapper .d2h-code-side-linenumber {
  height: 18px;
}
::v-deep .d2h-diff-table {
  font-family: RobotoCondensedRegular;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #f7f7f7;
}
.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: transparent;
}
.v-expansion-panel::before {
  box-shadow: none;
}
.v-expansion-panel-header__icon {
  margin-bottom: 6px !important;
}
.expansion-panel-header__icon {
  order: 0;
}
.expansion-panel-header__header {
  order: 1;
}
</style>
